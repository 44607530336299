[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-user-drag: element;
  -webkit-user-drag: element;
  -khtml-user-drag: element;
  user-drag: element; }

.hexagon {
  fill: "white";
  fill-opacity: 0.2; }

.hexagon polygon:hover {
  fill-opacity: 1; }

.hexagon:hover text {
  fill-opacity: 1; }

.hexagon polygon {
  stroke: #3f51b5;
  stroke-width: 0;
  transition: fill-opacity 0.2s; }

.hexagon text {
  font-size: 0.2em;
  fill: #000;
  fill-opacity: 0.9;
  transition: fill-opacity 0.2s; }

.hexPath {
  fill: none;
  stroke: rgba(0, 0, 0, 0);
  stroke-width: 0.4em;
  stroke-opacity: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round; }

td,
th {
  text-align: center !important;
  vertical-align: middle !important;
  font-size: 2em !important;
  font-weight: bold !important; }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-variant: small-caps; }

.carousel .slide {
  background: unset !important; }
